import React from "react";
import { Anchor, Box, Text, ResponsiveContext } from "grommet";
import Logo from "./Logos/Logo";
import { SocialMedia } from "./SocialMedia";
import Auth0LoginButton from 'Components/Auth0LoginButton'

const LandingHeader = () => (
    <ResponsiveContext.Consumer>
        {size => (
            <Box
                direction="row"
                justify="between"
                alignSelf="center"
                gap="medium"
                pad={{ top: "large", horizontal: "xlarge" }}
            >
            <Anchor
                href="/"
                icon={<Logo />}
                color="black"
            />
            <SocialMedia />
                <Auth0LoginButton/>
            </Box>
        )}
    </ResponsiveContext.Consumer>
);

export { LandingHeader };
