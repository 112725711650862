import React from "react";
import { Anchor, Box } from "grommet";
import { FacebookOption, Instagram, Twitter } from "grommet-icons";


const SocialMedia = () => (
    <Box direction="row" gap="xxsmall" justify="center" alignSelf={"center"}>
        <Anchor
            target="_blank"
            a11yTitle="Instagram"
            href="https://instagram.com/ClinicalTrialsIm"
            icon={<Instagram color="brand" size="medium" />}
        />
        <Anchor
            target="_blank"
            a11yTitle="Chat with us on Slack"
            href="https://www.facebook.com/ClinicalTrialsIm"
            icon={<FacebookOption color="brand" size="medium" />}
        />
        <Anchor
            target="_blank"
            a11yTitle="Follow us on Twitter"
            href="https://twitter.com/ClinicalTrialsIm"
            icon={<Twitter color="brand" size="medium" />}
        />

    </Box>
);

export { SocialMedia };
