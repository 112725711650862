import axios from "axios";
import {API_ENDPOINT} from "../config";

export async function searchClinicalTrials(SearchTerm: string): Promise<any> {
    const params = {
        SearchTerm: SearchTerm,
    }

    return axios.post(API_ENDPOINT  + '/clinicaltrials/search', params)
        .then(res => {
            console.log(res)
            return res.data.Payload
        })
}
