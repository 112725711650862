import axios from "axios";
import {API_ENDPOINT} from "config";




export function userExists(UserID: string): Promise<any> {

    const params = {
        UserID: UserID
    }

    return axios.post(API_ENDPOINT  + '/user/exists', params)
        .then(res => {
            const exists: boolean = (res.data.Payload === 'true');

            return exists
        })
}



export async function addUser(UserID: string, Profile: any): Promise<any> {

    const params = {
        UserID: UserID,
        Profile: Profile
    }

    return axios.post(API_ENDPOINT  + '/user/add', params)
        .then(res => {
            return res
        })
}

export async function getUser(UserID: string): Promise<any> {
    const params = {
        UserID: UserID
    }

    return axios.post(API_ENDPOINT  + '/user/get', params)
        .then(res => {
            console.log(res)
            return res.data.Payload
        })
}

export async function updateUserProfile(UserID: string, Profile: API.Profile): Promise<any> {
    const params = {
        UserID: UserID,
        Profile: Profile
    }

    return axios.post(API_ENDPOINT  + '/user/profile/update', params)
        .then(res => {
            console.log(res)
            return res.data.Error
        })
}


export async function updateSlackToken(UserID: string, Token: any): Promise<any> {
    const params = {
        UserID: UserID,
        Token: Token
    }

    return axios.post(API_ENDPOINT  + '/user/slack_token/update', params)
        .then(res => {
            console.log(res)
            return res.data.Error
        })
}


export async function addSearchTerms(UserID: string, Terms: any): Promise<any> {
    const params = {
        UserID: UserID,
        SearchTerms: Terms
    }

    console.log(params)

    return axios.post(API_ENDPOINT  + '/user/search_terms/add', params)
        .then(res => {
            console.log(res)
            return res.data.Error
        })
}

export async function postToSlackChannel(UserID: string, Text: string): Promise<any> {
    const params = {
        UserID: UserID,
        Text: Text
    }

    return axios.post(API_ENDPOINT  + '/slack/post', params)
        .then(res => {
            console.log(res)
            return res.data.Error
        })
}
