import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {
    Avatar,
    Box,
    Button,
    DropButton,
    Menu
} from 'grommet'


type Props = {}

const Auth0LoginButton: React.FC<Props> = (props) => {
    const { loginWithPopup, logout, isAuthenticated, user } = useAuth0();


    if(isAuthenticated) {
        const { name, picture, email } = user;

        return (
            <>


                    <Menu
                        // label="Fancy Selector"
                        items={[
                            // { label: 'First Action', onClick: () => {} },
                            { label: 'LogOut', onClick: () => {logout({ returnTo: window.location.origin})} },
                        ]}
                    >
                        <Avatar
                            src={picture}
                        />
                    </Menu>

            </>
        )
    } else {
        return (
            <Button primary label="Log In" onClick={() =>{loginWithPopup()}} />
        );
    }
};

export default Auth0LoginButton;
