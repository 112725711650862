import { grommet } from "grommet";
import { deepMerge } from "grommet/utils";

export const theme = deepMerge(grommet, {
  brand: "teal",
  global: {
    colors: {
      // active: '#ff99cc',
      brand: {
        "dark": "#005fee",
        "light": "#005fee"
      },
    },
    elevation: {
      light: {
        small: "0 0 1px 0 rgba(0, 0, 0, 0.40), 0 1px 2px 0 rgba(0,0,0,0.40)",
        medium: "0 0 2px 0 rgba(0,0,0,0.40), 0 2px 4px 0 rgba(0,0,0,0.40)",
        large: "0 0 1px 0 rgba(0,0,0,0.40), 0 4px 8px 0 rgba(0,0,0,0.40)",
        xlarge: "0 0 1px 0 rgba(0,0,0,0.40), 0 8px 16px 0 rgba(0,0,0,0.40)"
      }
    },
    size: {
      avatar: "55px",
      sidebar: "60px"
    },
    heading: {
      extend: () => `margin-top: 12px; margin-bottom: 12px;`
    },
    paragraph: {
      extend: () => `font-weight: 300; margin-top: 0;`,
      xxlarge: {
        size: "28px"
      }
    },
    textInput: {
      placeholder: {
        extend: () => `color: #44444`
      }
    },
    header: {

    }
  }
});
