import axios from "axios";
import {
    SLACK_CLIENT_ID,
    SLACK_CLIENT_SECRET,
} from "../config";


export function getSlackOauthAccessToken(code: string): Promise<any> {

    const headers = {
        "Content-Type" : "application/x-www-form-urlencoded"
    }

    return axios.get('https://slack.com/api/oauth.v2.access?code='+code+"&client_id="+SLACK_CLIENT_ID+"&client_secret="+SLACK_CLIENT_SECRET, {headers: headers})
        .then(res => {
            return res
        })
}
